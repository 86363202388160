<template>
  <div>
    <el-dialog
      @close="closePanel"
      width="45%"
      title="新增版本信息"
      :visible.sync="dialogShow"
      :close-on-click-modal="modal"
    >
      <el-form
        :model="form"
        label-width="110px"
        :rules="rules"
        ref="myForm"
        required
      >
        <el-form-item label="版本号：" prop="versionNumber">
          <el-input
            placeholder="请输入版本号"
            v-model="form.versionNumber"
          ></el-input>
        </el-form-item>
        <el-form-item label="版本包名称:" prop="versionName">
          <el-input
            placeholder="请输入版本包名称"
            v-model="form.versionName"
          ></el-input>
        </el-form-item>
        <el-form-item label="上传安装包" prop="versionUrl">
          <el-upload
            class="x-single-upload"
            drag
            ref="upload"
            action="#"
            name="file"
            accept=".apk"
            :show-file-list="false"
            :file-list="versionUrlList"
            :on-change="setContactList"
            :before-upload="beforeContactUpload"
            :http-request="doContactUpload"
          >
            <a
              class="font-primary"
              v-if="form.versionUrl"
              :href="form.versionUrl"
              target="_blank"
              >已上传文件（安装包），点击可下载</a
            >
            <div v-else>
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将安装包拖到此处，或
                <em>点击上传</em>
              </div>
            </div>
            <div class="el-upload__tip" slot="tip">
              （支持apk格式文件，大小不超过100M）
            </div>
          </el-upload>
        </el-form-item>
        <el-form-item label="版本描述：" prop="versionLog">
          <el-input
            type="textarea"
            :rows="6"
            placeholder="请输入版本描述"
            v-model="form.versionLog"
          ></el-input>
        </el-form-item>
      </el-form>

      <span slot="footer" class="btn">
        <el-button @click="closePanel" size="small">取 消</el-button>
        <el-button type="primary" @click="doValidate" size="small"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { FileUpload } from "@/api/common";
import { AppCreate, AppFileUpload } from "@/api/module/app";

let Brarules = {
  versionNumber: [
    {
      required: true,
      message: "请输入版本号",
      trigger: "blur",
    },
  ],
  versionLog: [
    {
      required: true,
      message: "请输入版本描述",
      trigger: "blur",
    },
  ],
  versionUrl: [
    {
      required: true,
      message: "请上传安装包",
      trigger: "change",
    },
  ],
  versionName: [
    {
      required: true,
      message: "请输入版本包名称",
      trigger: "blur",
    },
  ],
};

export default {
  name: "AppPanel",
  props: ["dialogShow", "id"],
  data() {
    return {
      modal: false,
      versionUrlList: [],
      form: {
        versionNumber: "",
        versionLog: "",
        versionUrl: "",
        versionName: "",
      },
      rules: Brarules,
    };
  },

  created() {},
  methods: {
    // 上传营业执照
    doContactUpload() {
      let oData = new FormData();
      const oFileList = this.versionUrlList;
      const len = oFileList.length;
      for (let i = 0; i < len; i++) {
        oData.append("name", `${this.form.versionName}.apk`);
        oData.append("file", oFileList[i].raw);
      }
      AppFileUpload(oData).then((r) => {
        console.log(r);
        if (r.code !== 0) return;
        this.form.versionUrl = r.data.file.url;
        this.$refs["myForm"].clearValidate("versionUrl");
      });
      this.versionUrlList = [];
    },
    // 限制合同大小与格式
    beforeContactUpload(file) {
      if (this.form.versionName === "") {
        console.log("11");
        this.$message.error("请输入版本包名称后再上传包文件");
        return false;
      } else {
        console.log("222");
        const sizeLimit = file.size / 1024 / 1024 < 100;
        if (!sizeLimit) {
          this.$message.error("上传安装包文件大小不能超过 100MB!");
          return false;
        }
        return true;
      }
      // if (["android"].indexOf(file.type.split("/")[2]) < 0) {
      //   this.$message.error("上传文件只能是 apk 格式!");
      //   return false;
      // }
    },

    // 选择合同
    setContactList(file, fileList) {
      this.versionUrlList = fileList;
    },

    doValidate() {
      let f = this.form;
      //   新增
      let oData = {
        AppTag: Number(1),
        versionNumber: f.versionNumber,
        versionLog: f.versionLog,
        versionUrl: f.versionUrl,
        versionName: f.versionName,
      };
      this.$refs.myForm.validate(async (val) => {
        if (val) {
          const r = await AppCreate(oData);
          //   全局验证通过;
          this.$message({
            type: "success",
            message: "上传成功",
            duration: 1300,
          });
          this.$emit("update:dialogShow", false);
          //调用父组件的方法
          this.$emit("updata");
          this.$refs["myForm"].resetFields(); // 在初始化所有字段
        } else {
          //全局验证不通过
        }
      });
    },
    closePanel() {
      this.form = {
        versionNumber: "",
        versionLog: "",
        versionUrl: "",
      };
      this.$emit("update:dialogShow", false);
      this.$refs.myForm.resetFields();
    },
  },
};
</script>

